import Dialog from '@mui/material/Dialog';
import { styled } from '@mui/material/styles';

const NotificationBoxStyle = styled(Dialog)(
  ({ theme }) => `
  .MuiPaper-root {
    width: 450px;
    height: 600px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.160784);
    border-radius: 4px;
    overflow: hidden;

    .header {
      display: flex;
      justify-content: space-between;
      box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
      padding: 16px;
      z-index: 1;
    }

    ${theme.breakpoints.down('sm')} {
      width: 100%;
      height: 100%;
      border-radius: 0px;
    }

    .scrollable {
      overflow: scroll;
    }
  }

  .close-button {
    padding: 0px;
    svg {
      font-size: 24px;
    }
  }
  `,
);

export default NotificationBoxStyle;
