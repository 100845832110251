import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'next-i18next';

import NotificationDialogStyle from './style';

import NotificationList from 'src/components/molecules/notification/notificationList';
import { useBooleanReduxState, useWindowSize } from 'src/helpers/use';

const NotificationDialog = () => {
  const [isOpen, setIsOpen] = useBooleanReduxState('isNotificationDialogOpen', 'set_notification_dialog_open');
  const { width = 0 } = useWindowSize();
  const theme = useTheme();
  const { t } = useTranslation('common');

  const onClose = () => {
    setIsOpen(false);
  };

  return (
    <NotificationDialogStyle open={isOpen} onClose={onClose} fullScreen={width < theme.breakpoints.values.sm}>
      <Box className="header">
        <Typography variant="h4">{t('my_notification')}</Typography>
        <IconButton color="primary" className="close-button" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box className="scrollable">
        <NotificationList />
      </Box>
    </NotificationDialogStyle>
  );
};

export default NotificationDialog;
